@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

#toggle-switch::after {
  content: '';
  @apply h-6 w-6 rounded-full bg-darkPurple absolute left-0 top-[-2px] transform scale-110 duration-200;
}

#toggle-switch:checked::after {
  @apply transform scale-110 translate-x-6;
}
